import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};

export const EmailLoginUserDocument = gql`
  mutation EmailLoginUser($email: String!, $password: String!) {
    emailLoginUser(email: $email, password: $password) {
      _updates
      id
      token
    }
  }
`;
export type EmailLoginUserMutationFn = Apollo.MutationFunction<
  Types.EmailLoginUserMutation,
  Types.EmailLoginUserMutationVariables
>;
export type EmailLoginUserProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.EmailLoginUserMutation,
    Types.EmailLoginUserMutationVariables
  >;
} &
  TChildProps;
export function withEmailLoginUser<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.EmailLoginUserMutation,
    Types.EmailLoginUserMutationVariables,
    EmailLoginUserProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.EmailLoginUserMutation,
    Types.EmailLoginUserMutationVariables,
    EmailLoginUserProps<TChildProps, TDataName>
  >(EmailLoginUserDocument, {
    alias: "emailLoginUser",
    ...operationOptions,
  });
}

/**
 * __useEmailLoginUserMutation__
 *
 * To run a mutation, you first call `useEmailLoginUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailLoginUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailLoginUserMutation, { data, loading, error }] = useEmailLoginUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useEmailLoginUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.EmailLoginUserMutation,
    Types.EmailLoginUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.EmailLoginUserMutation,
    Types.EmailLoginUserMutationVariables
  >(EmailLoginUserDocument, options);
}
export type EmailLoginUserMutationHookResult = ReturnType<
  typeof useEmailLoginUserMutation
>;
export type EmailLoginUserMutationResult =
  Apollo.MutationResult<Types.EmailLoginUserMutation>;
export type EmailLoginUserMutationOptions = Apollo.BaseMutationOptions<
  Types.EmailLoginUserMutation,
  Types.EmailLoginUserMutationVariables
>;
export const UpdateStaticContentDocument = gql`
  mutation UpdateStaticContent($id: ID!, $content: String!) {
    updateStaticContent(id: $id, content: $content) {
      _updates
    }
  }
`;
export type UpdateStaticContentMutationFn = Apollo.MutationFunction<
  Types.UpdateStaticContentMutation,
  Types.UpdateStaticContentMutationVariables
>;
export type UpdateStaticContentProps<
  TChildProps = {},
  TDataName extends string = "mutate"
> = {
  [key in TDataName]: Apollo.MutationFunction<
    Types.UpdateStaticContentMutation,
    Types.UpdateStaticContentMutationVariables
  >;
} &
  TChildProps;
export function withUpdateStaticContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "mutate"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.UpdateStaticContentMutation,
    Types.UpdateStaticContentMutationVariables,
    UpdateStaticContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withMutation<
    TProps,
    Types.UpdateStaticContentMutation,
    Types.UpdateStaticContentMutationVariables,
    UpdateStaticContentProps<TChildProps, TDataName>
  >(UpdateStaticContentDocument, {
    alias: "updateStaticContent",
    ...operationOptions,
  });
}

/**
 * __useUpdateStaticContentMutation__
 *
 * To run a mutation, you first call `useUpdateStaticContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStaticContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStaticContentMutation, { data, loading, error }] = useUpdateStaticContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      content: // value for 'content'
 *   },
 * });
 */
export function useUpdateStaticContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateStaticContentMutation,
    Types.UpdateStaticContentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateStaticContentMutation,
    Types.UpdateStaticContentMutationVariables
  >(UpdateStaticContentDocument, options);
}
export type UpdateStaticContentMutationHookResult = ReturnType<
  typeof useUpdateStaticContentMutation
>;
export type UpdateStaticContentMutationResult =
  Apollo.MutationResult<Types.UpdateStaticContentMutation>;
export type UpdateStaticContentMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateStaticContentMutation,
  Types.UpdateStaticContentMutationVariables
>;
