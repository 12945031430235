import { FieldMergeFunction, FieldPolicy, gql, InMemoryCache, TypePolicies, TypePolicy } from "@apollo/client";
import { Modifiers } from "@apollo/client/cache/core/types/common";
import mutationList from "./serverInfos/mutationList.json";
import Models from "./serverInfos/models.json";

interface DataItemEssentials {
  __typename: string;
  id: string;
}

type DataItem = DataItemEssentials & { [key: string]: any };

type DataItems = DataItem[];

interface Data {
  create: DataItems;
  update: DataItems;
  delete: DataItems;
};

type Dependencies = { [key: string]: { [key: string]: { model: string, attribute: string } } };

interface CollectionAttributeProperties {
  name: string;
  type: string;
  model: string;
  via: string;
}

const constructCollectionFieldsDependencies = () => {
  const dependencies = Object.keys(Models).reduce<Dependencies>((dependencies, modelName) => {
    dependencies[modelName] = {};
    return dependencies;
  }, {});

  for (const [modelName, attributes] of Object.entries(Models)) {
    for (const attribute of Object.values(attributes) as unknown[]) {
      const attributeInfos = attribute as CollectionAttributeProperties;

      if (attributeInfos.type === 'collection') {
        dependencies[attributeInfos.model][attributeInfos.via] = {
          model: modelName,
          attribute: attributeInfos.name,
        };
      }
    }
  }

  return dependencies;
};

const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

const createMergePolicies = (): TypePolicies => {

  const collectionFieldsDependencies = constructCollectionFieldsDependencies();

  let requestIdCounter = 0;

  const buildCreationQuery = (fields: string[]) => {
    const requestId = requestIdCounter++;
  
    const query = gql`query CreateCacheObj {
      obj${requestId} {
        ${fields.join('\n')}
      }
    }`;
  
    return {
      requestId,
      query,
    };
  };
  
  const mergeCreate = (cache: InMemoryCache, data: Data) => {
    for (const params of data.create) {

      const { requestId, query } = buildCreationQuery(Object.keys(params));
  
      cache.writeQuery({
        query,
        data: {
          [`obj${requestId}`]: params,
        }
      });
    }
  };

  const getCacheRefId = (cachedValue: null | { __ref: string }) => {
    if (cachedValue === null) {
      return null;
    }

    const separatorIndex = cachedValue.__ref.indexOf(':');
    return cachedValue.__ref.substring(separatorIndex + 1);
  };
  
  const mergeUpdate = (cache: InMemoryCache, data: Data) => {
    for (const { id, __typename, ...rest } of data.update) {
      const modifiers = Object.entries(rest).reduce<Modifiers>((fieldGetters, [name, value]) => {
        fieldGetters[name] = (cachedValue) => {
          const dependency = collectionFieldsDependencies[__typename][name];

          if (dependency !== undefined) {
            const cachedId = getCacheRefId(cachedValue);

            if (cachedId !== null) {
              cache.modify({
                id: cache.identify({ id: cachedId, __typename: dependency.model }),
                fields: {
                  [dependency.attribute]: (cachedCollection) => {
                    if (Array.isArray(cachedCollection)) {
                      return cachedCollection.filter(ref => getCacheRefId(ref) !== id);
                    }
                  },
                },
              });
            }

            cache.modify({
              id: cache.identify({ id: value, __typename: dependency.model }),
              fields: {
                [dependency.attribute]: (cachedCollection) => {
                  if (Array.isArray(cachedCollection)) {
                    return [...cachedCollection, { __ref: `${__typename}:${id}` }];
                  }
                },
              },
            });
          }

          return value;
        };

        return fieldGetters;
      }, {});
    
      cache.modify({
        id: cache.identify({ id, __typename }),
        fields: modifiers,
      });
    }
  };
  
  const mergeDelete = (cache: InMemoryCache, data: Data) => {
    for (const { id, __typename } of data.delete) {  
      cache.evict({
        id: cache.identify({ id, __typename }),
      });
    }
  
    cache.gc();
  };
  
  const merge: FieldMergeFunction<any, { _updates?: string }> = (existing, incoming, { cache }) => {
    if (incoming._updates !== undefined) {
      const data: Data = JSON.parse(incoming._updates);
  
      mergeCreate(cache, data);
      mergeUpdate(cache, data);
      mergeDelete(cache, data);
    }
  };


  const fields = mutationList.reduce<{ [key: string]: FieldPolicy<any> }>((fields, mutationName) => {
    fields[mutationName] = {
      merge,
    };

    return fields;
  }, {});

  const mutationTypesPolicies = mutationList.reduce<{ [key: string]: TypePolicy }>((policies, mutationName) => {
    policies[capitalizeFirstLetter(mutationName)] = {
      keyFields: false,
    };

    return policies;
  }, {});

  return {
    Mutation: {
      fields,
    },
    ...mutationTypesPolicies,
  };
};

export default createMergePolicies;