import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { withScalars } from "apollo-link-scalars";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import createMergePolicies from "./mergeCache";
import config from "./config.json";
import schemaDefinition from "./serverInfos/schema.json";
import { dateScalar, dateTimeScalar } from './staticScalarTypes';
import { BrowserRouter } from 'react-router-dom';

const httpLink = createHttpLink({
  uri: `${config.SERVER_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});

const scalarTypesLink = withScalars({
  schema: buildClientSchema(schemaDefinition.data as unknown as IntrospectionQuery),
  typesMap: {
    Date: dateScalar,
    DateTime: dateTimeScalar,
  }
});

const client = new ApolloClient({
  link: ApolloLink.from([
    scalarTypesLink as unknown as ApolloLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache({
    typePolicies: createMergePolicies(),
  }),
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
