import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useEmailLoginUserMutation } from './apollo/mutations';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

interface SignInProps {
  setToken: (token: string) => void;
}

const SignIn: React.FC<SignInProps> = ({ setToken }) => {
  const classes = useStyles();
  const [emailLoginUserMutation] = useEmailLoginUserMutation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginFailed, setLoginFailed] = useState(false);

  const handleLogin = (event: React.MouseEvent) => {
    event.preventDefault();
    emailLoginUserMutation({
      variables: {
        email,
        password,
      },
    })
      .then(({ data }) => {
        if (data?.emailLoginUser.token !== undefined) {
          setToken(data?.emailLoginUser.token);
        }
      })
      .catch(() => setLoginFailed(true));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Email Address"
            autoFocus
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Password"
            type="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={handleLogin}
          >
            Sign In
          </Button>
          {/* <Link href="#" variant="body2">
            Forgot password?
          </Link> */}
          <Snackbar open={loginFailed} autoHideDuration={6000} onClose={() => setLoginFailed(false)}>
            <Alert onClose={() => setLoginFailed(false)} variant="filled" severity="error">
              Login failed
            </Alert>
          </Snackbar>
        </form>
      </div>
    </Container>
  );
};

export default SignIn;