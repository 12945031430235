import { CircularProgress, makeStyles } from "@material-ui/core";

const loaderSize = '40px';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    top: `calc(50% - ${loaderSize})`,
    left: `calc(50% - ${loaderSize})`,
  },
}));

const Loader = () => {
  const classes = useStyles();

  return <div className={classes.wrapper}>
    <CircularProgress />
  </div>;
};

export default Loader;