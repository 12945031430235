import { useHistory } from "react-router-dom";
import { useFindStaticContentQuery } from "../apollo/queries";
import Table from "../components/Table";
import Loader from "../Loader";

const columns = [
  {
    field: 'name',
  },
];

const StaticContent = () => {
  const history = useHistory();

  const { loading, data } = useFindStaticContentQuery();

  if (loading) {
    return <Loader />;
  }

  return (
    <Table
      columns={columns}
      loading={loading}
      records={data?.findStaticContent || []}
      onRowClick={(row) => {
        history.push(`/static-content/${row.id}`);
      }}
    />
  );
};

export default StaticContent;