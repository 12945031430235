import { GraphQLScalarType, Kind } from "graphql";

// Always uses local representations
const dateRegex = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})$/;

const localDateToSQL = (date: Date) => {
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const sqlToLocalDate = (value: string) => {
  const match = value.match(dateRegex);

  if (!match?.groups) {
    return null;
  }

  return new Date(
    Number(match.groups.year),
    Number(match.groups.month) - 1,
    Number(match.groups.day)
  );
};

export const dateScalar = new GraphQLScalarType({
  name: 'Date',
  description: 'Date scalar type',
  serialize: localDateToSQL,
  parseValue: sqlToLocalDate,
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING) {
      return sqlToLocalDate(ast.value);
    }

    return null;
  },
});

// Always uses utc representations
export const dateTimeScalar = new GraphQLScalarType({
  name: 'DateTime',
  description: 'DateTime scalar type',
  serialize(date: Date) {
    return date.toISOString();
  },
  parseValue(value: string) {
    return new Date(value);
  },
  parseLiteral(ast) {
    if (ast.kind === Kind.STRING) {
      return new Date(ast.value);
    }

    if (ast.kind === Kind.INT) {
      return new Date(parseInt(ast.value, 10));
    }

    return null;
  },
});