import React, { useState } from 'react';
import Dashboard from './Dashboard';
import SignIn from './SignIn';
import '@react-page/editor/lib/index.css';

const App = () => {

  const [trigger, setTrigger] = useState(false);
  const isConnected = localStorage.getItem('token') !== null;

  const setToken = (token: string) => {
    localStorage.setItem('token', token);
    setTrigger(!trigger);
  };

  return <>
    {
      isConnected ? <Dashboard /> : <SignIn setToken={setToken} />
    }
  </>;
}

export default App;
