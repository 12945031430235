import { createObjectCsvStringifier } from 'csv-writer';
import { useFindUsersQuery } from "../apollo/queries";
import Table, { ActionHandler } from "../components/Table";
import Loader from "../Loader";

const columns = [
  {
    field: 'firstName',
  },
  {
    field: 'lastName',
  },
  {
    field: 'email',
  },
  {
    field: 'language',
  },
  {
    field: 'newsletter',
  },
];

const downloadTxtFile = (filename: string, content: string) => {
  const element = document.createElement("a");
  const file = new Blob([content], { type: 'text/plain;charset=utf-8' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
};

const toCsv = (columns: string[], records: Record<string, string>[]) => {
  const csvStringifier = createObjectCsvStringifier({
    header: columns.map(column => ({
      id: column,
      title: column,
    })),
  });

  return `${csvStringifier.getHeaderString()}${csvStringifier.stringifyRecords(records)}`;
};

const transformItem = (item: Record<string, any>): Record<string, string> => {
  return {
    ...item,
    newsletter: item.newsletter ? 'True' : 'False',
  };
};

const Users = () => {

  const { loading, data } = useFindUsersQuery();

  if (loading) {
    return <Loader />;
  }

  const downloadAsFile: ActionHandler = ({ columns, records }) => {
    downloadTxtFile('users.csv', toCsv(columns.map(({ field }) => field), records.map(transformItem)));
  };

  return (
    <Table
      columns={columns}
      loading={loading}
      records={data?.findUser || []}
      transformRecord={transformItem}
      actions={[
        {
          title: 'Download as CSV',
          handler: downloadAsFile,
        }
      ]}
    />
  );
};

export default Users;