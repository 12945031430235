import GroupIcon from '@material-ui/icons/Group';
import SubjectIcon from '@material-ui/icons/Subject';
import React from 'react';
import Users from './pages/Users';
import StaticContent from './pages/StaticContent';
import StaticContentItem from './pages/StaticContentItem';

export interface BaseItem {
  route: string;
  content: React.ComponentType;
}

export interface MenuItemProps {
  route: string;
  title: string;
  icon: React.ComponentType;
  default?: boolean;
}

export type MenuItem = BaseItem & MenuItemProps;

export type Item = BaseItem | MenuItem;

export type Items = Array<Item>;

const items: Items = [
  {
    route: '/users',
    title: 'Users',
    icon: GroupIcon,
    content: Users,
    default: true,
  },
  {
    route: '/static-content',
    title: 'Static content',
    icon: SubjectIcon,
    content: StaticContent,
  },
  {
    route: '/static-content/:id',
    content: StaticContentItem,
  },
];

export default items;