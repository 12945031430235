import { useParams } from "react-router-dom";
import type { CellPlugin, Value } from '@react-page/editor';
import Editor from '@react-page/editor';
import { useGetStaticContentQuery } from "../apollo/queries";
import Loader from "../Loader";
import { useState, useEffect } from "react";
import slate from '@react-page/plugins-slate';
import { Button, CircularProgress, makeStyles, Snackbar } from "@material-ui/core";
import { useUpdateStaticContentMutation } from "../apollo/mutations";
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: '1rem',
  },
}));

const cellPlugins = [slate()];

const StaticContentItem = () => {
  const classes = useStyles();
  const id = useParams<{ id: string }>().id;
  const [updateStaticContentMutation] = useUpdateStaticContentMutation();

  const [value, setValue] = useState<Value | null>(null);
  const [dirty, setDirty] = useState<boolean>(false);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [failed, setFailed] = useState(false);

  const { loading, data } = useGetStaticContentQuery({
    variables: {
      id,
    }
  });

  useEffect(() => {
    if (data?.getStaticContent?.content !== undefined) {
      setValue(JSON.parse(data?.getStaticContent?.content));
    }
  }, [data]);

  const update = (value: Value) => {
    setValue(value);
    setDirty(true);
  };

  const save = () => {
    setSaveLoading(true);
    updateStaticContentMutation({
      variables: {
        id,
        content: JSON.stringify(value),
      },
    })
      .then(() => {
        setSaveLoading(false);
        setDirty(false);
      })
      .catch((error) => {
        setFailed(true);
        console.error(error);
      });
  };

  if (loading) {
    return <Loader />;
  }

  return <>
    <div className={classes.header}>
      <Button variant="contained" color="primary" disabled={!dirty} onClick={save} startIcon={saveLoading && <CircularProgress size={20} />}>
        Save
      </Button>
    </div>
    <Editor
      cellPlugins={cellPlugins as CellPlugin[]}
      value={value}
      onChange={update}
      allowMoveInEditMode={false}
      allowResizeInEditMode={false}
      hideEditorSidebar
      childConstraints={{ maxChildren: 1 }}
    />
    <Snackbar open={failed} autoHideDuration={6000} onClose={() => setFailed(false)}>
      <Alert onClose={() => setFailed(false)} variant="filled" severity="error">
        An error as occured
      </Alert>
    </Snackbar>
  </>;
};

export default StaticContentItem;