import { Button, makeStyles, Theme } from "@material-ui/core";
import { DataGrid, GridColumns } from '@material-ui/data-grid';
import React from "react";
import { useMemo } from "react";
import Loader from "../Loader";

export interface ActionHandlerArgs {
  records: Record<string, any>[];
  columns: GridColumns;
}

export type ActionHandler = (args: ActionHandlerArgs) => void | Promise<void>;

export interface Action {
  title: string;
  handler: ActionHandler;
}

export interface TableProps {
  columns: GridColumns;
  actions?: Action[];
  records: Record<string, any>[] | null;
  transformRecord?: (record: Record<string, any>) => Record<string, string>;
  loading: boolean;
  onRowClick?: (row: Record<string, any>) => void;
}

const useStyles = makeStyles<Theme, TableProps>({
  header: {
    marginBottom: '1rem',
  },
  datagrid: {
    cursor: ({ onRowClick }) => onRowClick ? 'pointer' : 'inherit',
  },
});

const processColumnHeaderName = (field: string): string => {
  const result = field.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const processColumns = (columns: GridColumns): GridColumns => {
  return columns.map(column => {
    const processedColumn = {
      ...column,
      headerName: column.headerName || processColumnHeaderName(column.field),
    };

    if (processedColumn.width === undefined && processedColumn.flex === undefined) {
      processedColumn.flex = 1;
    }

    return processedColumn;
  });
};

const defaultTransformRecord = (record: Record<string, any>) => Object.entries(record).reduce<Record<string, string>>((transformedRecord, [key, value]) => {
  transformedRecord[key] = value.toString();
  return transformedRecord;
}, {});

const Table: React.FC<TableProps> = (props) => {
  const classes = useStyles(props);
  const { columns, actions, records, transformRecord=defaultTransformRecord, loading, onRowClick } = props;

  const processedColumns = useMemo(() => processColumns(columns), [columns]);

  if (loading) {
    return <Loader />;
  }

  const ensuredRecords = records as Record<string, any>[];
  const transformedRecords = ensuredRecords.map(transformRecord);

  return <>
    <div className={classes.header}>
      {
        (actions || []).map((action, index) => (
          <Button key={index} variant="contained" color="primary" onClick={() => action.handler({ records: ensuredRecords, columns: processedColumns })}>
            {action.title}
          </Button>
        ))
      }
    </div>
    <DataGrid
      className={classes.datagrid}
      rows={transformedRecords}
      columns={processedColumns}
      checkboxSelection
      disableSelectionOnClick
      onRowClick={onRowClick}
    />
  </>;
};

export default Table;