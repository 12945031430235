import * as Types from "./types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
import * as ApolloReactHoc from "@apollo/client/react/hoc";
const defaultOptions = {};

export const FindStaticContentDocument = gql`
  query FindStaticContent($filter: String) {
    findStaticContent(filter: $filter) {
      id
      name
    }
  }
`;
export type FindStaticContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables
  >;
} &
  TChildProps;
export function withFindStaticContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables,
    FindStaticContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables,
    FindStaticContentProps<TChildProps, TDataName>
  >(FindStaticContentDocument, {
    alias: "findStaticContent",
    ...operationOptions,
  });
}

/**
 * __useFindStaticContentQuery__
 *
 * To run a query within a React component, call `useFindStaticContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindStaticContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindStaticContentQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindStaticContentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables
  >(FindStaticContentDocument, options);
}
export function useFindStaticContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindStaticContentQuery,
    Types.FindStaticContentQueryVariables
  >(FindStaticContentDocument, options);
}
export type FindStaticContentQueryHookResult = ReturnType<
  typeof useFindStaticContentQuery
>;
export type FindStaticContentLazyQueryHookResult = ReturnType<
  typeof useFindStaticContentLazyQuery
>;
export type FindStaticContentQueryResult = Apollo.QueryResult<
  Types.FindStaticContentQuery,
  Types.FindStaticContentQueryVariables
>;
export const FindUsersDocument = gql`
  query FindUsers($filter: String) {
    findUser(filter: $filter) {
      id
      email
      firstName
      lastName
      language
      newsletter
    }
  }
`;
export type FindUsersProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables
  >;
} &
  TChildProps;
export function withFindUsers<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables,
    FindUsersProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables,
    FindUsersProps<TChildProps, TDataName>
  >(FindUsersDocument, {
    alias: "findUsers",
    ...operationOptions,
  });
}

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFindUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Types.FindUsersQuery, Types.FindUsersQueryVariables>(
    FindUsersDocument,
    options
  );
}
export function useFindUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FindUsersQuery,
    Types.FindUsersQueryVariables
  >(FindUsersDocument, options);
}
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<
  typeof useFindUsersLazyQuery
>;
export type FindUsersQueryResult = Apollo.QueryResult<
  Types.FindUsersQuery,
  Types.FindUsersQueryVariables
>;
export const GetStaticContentDocument = gql`
  query GetStaticContent($id: ID!) {
    getStaticContent(id: $id) {
      id
      name
      content
    }
  }
`;
export type GetStaticContentProps<
  TChildProps = {},
  TDataName extends string = "data"
> = {
  [key in TDataName]: ApolloReactHoc.DataValue<
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables
  >;
} &
  TChildProps;
export function withGetStaticContent<
  TProps,
  TChildProps = {},
  TDataName extends string = "data"
>(
  operationOptions?: ApolloReactHoc.OperationOption<
    TProps,
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables,
    GetStaticContentProps<TChildProps, TDataName>
  >
) {
  return ApolloReactHoc.withQuery<
    TProps,
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables,
    GetStaticContentProps<TChildProps, TDataName>
  >(GetStaticContentDocument, {
    alias: "getStaticContent",
    ...operationOptions,
  });
}

/**
 * __useGetStaticContentQuery__
 *
 * To run a query within a React component, call `useGetStaticContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticContentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStaticContentQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables
  >(GetStaticContentDocument, options);
}
export function useGetStaticContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetStaticContentQuery,
    Types.GetStaticContentQueryVariables
  >(GetStaticContentDocument, options);
}
export type GetStaticContentQueryHookResult = ReturnType<
  typeof useGetStaticContentQuery
>;
export type GetStaticContentLazyQueryHookResult = ReturnType<
  typeof useGetStaticContentLazyQuery
>;
export type GetStaticContentQueryResult = Apollo.QueryResult<
  Types.GetStaticContentQuery,
  Types.GetStaticContentQueryVariables
>;
